<template>
  <header
    class="flex justify-between w-full"
    :style="{
      '--text-color': textColor ?? '#ECEBD2',
    }"
  >
    <div
      class="flex items-center justify-center border border-solid border-black bg-[#F2F1DC] rounded-full w-12 xl:w-[60px] h-12 xl:h-[60px]"
    >
      <p class="font-Kontesa font-semibold text-2xl xl:text-[30px]">GC</p>
    </div>
    <div v-if="text" class="hidden xl:block w-1/6 min-w-[270px] max-w-[291px]">
      <p
        class="font-Kontesa font-medium text-base lg:text-2xl text-center text-[--text-color]"
      >
        {{ text }}
      </p>
      <p
        class="block xl:hidden text-shadow font-Kontesa text-[#ECEBD2] text-[64px] xl:text-[156px] text-center font-bold"
      >
        giving circles
      </p>
    </div>
    <NuxtLink
      v-if="self"
      class="relative flex flex-col items-center w-[45px] xl:w-12 h-[51px] xl:h-[61px]"
      :to="useBaseDomainURL('/profile')"
      :prefetch="false"
    >
      <AvatarRoot
        class="inline-flex w-10 h-10 xl:w-12 xl:h-12 select-none items-center justify-center overflow-hidden rounded-full align-middle bg-white"
      >
        <AvatarImage
          v-if="self.avatarUrl"
          class="h-full w-full rounded-[inherit] object-cover"
          :src="self.avatarUrl"
          :alt="self.name"
        />
        <AvatarFallback
          class="flex h-full w-full items-center justify-center rounded-[inherit] border border-solid border-black font-Kontesa font-medium"
        >
          {{ self.initials }}
        </AvatarFallback>
      </AvatarRoot>
      <p
        class="absolute -bottom-1 font-Kontesa font-semibold text-center text-xs xl:text-sm border border-solid border-black bg-white rounded-md w-11 xl:w-[50px]"
      >
        Level {{ self.level }}
      </p>
    </NuxtLink>
    <div
      v-else
      class="relative flex flex-col items-center justify-center w-[45px] xl:w-12 h-[51px] xl:h-[61px]"
    >
      <NuxtLink v-if="!noLogin" to="/donor/login" :prefetch="false">
        <div
          class="flex items-center justify-center border border-solid border-black bg-[#F2F1DC] rounded-full w-12 xl:w-[70px] h-8 xl:h-[45px]"
        >
          <p class="text-lg font-Kontesa font-medium">Login</p>
        </div>
      </NuxtLink>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useQuery } from "@tanstack/vue-query";
import { authQueries } from "~/domains/auth/queries";

defineProps<{
  textColor?: string;
  noLogin?: boolean;
  text?: string;
}>();

const { data: self } = useQuery({
  ...authQueries.self(),
  select: (data) =>
    data
      ? {
          avatarUrl: data.avatarUrl,
          name: data.name,
          level: data.members.reduce((acc, m) => acc + m.points, 0),
          initials: getInitials(data.name),
        }
      : null,
});

function getInitials(name: string) {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/u, "gu");
  let initials = [...name.matchAll(rgx)];
  return (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "");
}
</script>
